import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";

const CenteredContent = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section">
      <div className="container mx-auto">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment CenteredContent on WpPage_Sections_Sections_CenteredContent {
    fieldGroupName
    name
    content
  }
`;

export default CenteredContent;
