import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";

const ContentWithImage = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section content-with-image">
      <div className="container mx-auto">
        <div className="row">
          <div className="col-lg-5 pb-5 order-lg-last">
            <CmsImage className="content-image" image={data.image} />
          </div>
          <div className="col-lg-7 ">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContentWithImage on WpPage_Sections_Sections_ContentWithImage {
    fieldGroupName
    name
    content
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default ContentWithImage;
