import React from "react";
import sectionComponents from "../../utilities/sections";

// Loop through the sections and display the correct component

const RenderSections = ({ sections, prefix, location }) => {
  return (
    <>
      {sections &&
        sections.map((section) => {
          // if(section != '') return false;

          const sectionName = section.fieldGroupName.replace(
              `${prefix ? prefix : "Page"}_Sections_Sections_`,
              ""
            ),
            Section = sectionComponents[sectionName];
          return (
            <Section data={section} key={section.name} location={location} />
          );
        })}
    </>
  );
};

export default RenderSections;
