import React, { useState, useContext, useCallback } from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import L from "leaflet";
import {
  MapContainer as BaseMap,
  TileLayer,
  ZoomControl,
  Marker,
  Tooltip,
} from "react-leaflet";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";
// import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import icon from "../../images/icon-map-marker.png";
import iconRetina from "../../images/icon-map-marker.png";
import iconShadow from "../../images/icon-map-marker.png";

import SelectedLocationContext from "../../store/selected-location-context";

import Link from "../utilities/link";
import Content from "../utilities/content";

const isDomAvailable = typeof window !== "undefined";

if (isDomAvailable) {
  // To get around an issue with the default icon not being set up right between using React
  // and importing the leaflet library, we need to reset the image imports
  // See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetina,
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
}

let markerRefs = [];

const LocationMap = ({ children, data }) => {
  const selectedLocationCtx = useContext(SelectedLocationContext);
  const [map, setMap] = useState(null);

  const onLocationHover = useCallback(
    (location) => {
      // show popup on hovered location
      for (let key in markerRefs) {
        markerRefs[key].closeTooltip();
      }
      markerRefs[location.location].openTooltip();
      // center view
      map.setView([location.latitude, location.longitude], 10);
    },
    [map]
  );

  if (!isDomAvailable) {
    return (
      <div className="map">
        <p className="map-loading">Loading map...</p>
      </div>
    );
  }

  const getRandomCoordinates = function (data) {
    let length = data.locations.length;
    let index = Math.floor(Math.random() * length);
    let location = data.locations[index];

    return [location.latitude, location.longitude];
  };

  const mapSettings = {
    className: "map-base",
    zoomControl: false,
    center: getRandomCoordinates(data),
    zoom: 10,
  };

  function setUserLocation(location) {
    localStorage.setItem("location", JSON.stringify(location));

    selectedLocationCtx.setSelectedLocation(location);
  }

  return (
    <section id={convertId(data.name)} className="section-location-map">
      <div className="map">
        <div className="scroll-wrap-location">
          <div className="scroll-location">
            <div className="text-center">
              <h1>Find An Amigo Location</h1>
            </div>

            <div className="location-wrapper">
              <ul>
                {data.locations &&
                  data.locations.map((loc, key) => (
                    <li key={key} className="">
                      {loc.page && (
                        <Link
                          to={loc.page.uri}
                          onClick={(e) => setUserLocation(loc)}
                          onMouseEnter={() => {
                            onLocationHover(loc);
                          }}
                        >
                          <div>
                            <h2>{loc.location}</h2>
                            <Content className="p-0 location-add">
                              {loc.address}
                            </Content>
                          </div>
                        </Link>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <BaseMap {...mapSettings} whenCreated={setMap}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {data.locations &&
            data.locations.map((loc, key) => (
              <div key={key}>
                <Marker
                  position={[loc.latitude, loc.longitude]}
                  ref={(ref) => (markerRefs[loc.location] = ref)}
                >
                  <Tooltip>
                    <b>{loc.location}</b>
                    <br />
                    <Content className="p-0 location-add">
                      {loc.address}
                    </Content>
                  </Tooltip>
                </Marker>
              </div>
            ))}

          {/* <ZoomControl position="bottomright" /> */}
          {children}
        </BaseMap>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment LocationMap on WpPage_Sections_Sections_LocationMap {
    fieldGroupName
    name
    locations {
      address
      fieldGroupName
      latitude
      location
      longitude
      orderButtonLabel
      orderButtonLink
      page {
        ... on WpPage {
          id
          slug
          title
          uri
          link
        }
      }
    }
  }
`;

export default LocationMap;
