import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql, useStaticQuery } from "gatsby";
// import CmsImage from "../utilities/cms-image";

import LocationConfettiLeft from "../../images/confetti-location-left.svg";
import LocationConfettiLeftBottom from "../../images/confetti-location-left-bottom.svg";
import LocationConfettiRight from "../../images/confetti-location-right.svg";

import {
  MapContainer as BaseMap,
  TileLayer,
  Marker,
  Tooltip,
} from "react-leaflet"; // ZoomControl
import HeadingTag from "../utilities/heading-tag";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";

const FeaturedMap = ({ children, data }) => {
  const {
    wpPage: {
      sections: { sections: locations_wp },
    },
  } = useStaticQuery(graphql`
    query LocationQuery {
      wpPage(uri: { eq: "/locations/" }) {
        id
        uri
        sections {
          sections {
            ... on WpPage_Sections_Sections_LocationMap {
              locations {
                address
                latitude
                location
                longitude
              }
            }
          }
        }
      }
    }
  `);

  let locations = locations_wp[1].locations;

  const getRandomCoordinates = function (data) {
    let length = locations.length;
    let index = Math.floor(Math.random() * length);
    let location = locations[index];

    return [location.latitude, location.longitude];
  };

  const mapSettings = {
    zoomControl: false,
    center: getRandomCoordinates(data),
    zoom: 10,
    scrollWheelZoom: false
  };

  return (
    <>
      {/* create sections locatiom */}
      <section id={convertId(data.name)} className="section-featured-location">
        <div className="container position-relative">
          <div className="text-left" data-aos="fade-down">
            <HeadingTag
              className={`location-title textStroke-${data.headingTextStroke[0].color}`}
              dangerouslySetInnerHTML={{
                __html: data.headingTextStroke[0].title,
              }}
            ></HeadingTag>
          </div>
          {/* <CmsImage image={data.image} /> */}
          <div className="row">
            <div className="col-lg-6 mb-2-6 py-4" data-aos="fade-down">
              <img
                className="confetti-left"
                src={LocationConfettiLeft}
                alt=""
                data-aos="fade-right"
              />
              <img
                className="confetti-left-bottom"
                src={LocationConfettiLeftBottom}
                alt=""
                data-aos="fade-left"
              />
              <div className="card wrapper-location">
                <BaseMap className="wrapper-map" {...mapSettings}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {locations &&
                    locations.map((loc, key) => (
                      <div key={key}>
                        <Marker position={[loc.latitude, loc.longitude]}>
                          <Tooltip>
                            <b>{loc.loc}</b>
                            <br />
                            <Content className="p-0 location-add">
                              {loc.address}
                            </Content>
                          </Tooltip>
                        </Marker>
                      </div>
                    ))}

                  {/* <ZoomControl position="bottomright" /> */}
                  {children}
                </BaseMap>
                {/* <div className="wrapper-map">
                                    <img className="" src={sampleMap} alt="" />
                                </div> */}
              </div>
            </div>
            <div
              className="col-lg-6 mb-2-6 align-self-center"
              data-aos="fade-left"
            >
              <div className="intro-title">
                {/* <h1>{data.heading}</h1> */}

                <div
                  className="featured-map-content"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></div>
              </div>

              {data.button && (
                <div className="map-page-route mt-5">
                  <CmsLink className="btn btn-primary btn-hover-icon" link={data.button} />
                </div>
              )}

            </div>
          </div>
        </div>

        <div className="position-relative">
          <img className="confetti-right" src={LocationConfettiRight} alt="" />
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment FeaturedMap on WpPage_Sections_Sections_FeaturedMap {
    fieldGroupName
    name
    headingTextStroke {
      fieldGroupName
      color
      title
      titleTag
    }
    content
    button {
      target
      title
      url
    }
  }
`;

export default FeaturedMap;
