import React, { useEffect, useState, useRef } from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import IconBack from "../../images/icon-back.svg";
import Link from "../utilities/link";
import AmigoConfettiLeft from "../../images/confetti-branch-location-left.svg";
import AmigoConfettiRight from "../../images/confetti-branch-location-right.svg";
import Scrollspy from "react-scrollspy";
import HeadingTag from "../utilities/heading-tag";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";

const BranchLocation = ({ data, location }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const [initialTab, setInitialTab] = useState(false);
  const menusTabs = useRef();

  let searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (initialTab === false && searchParams.get("tab")) {
      let elem = document.querySelector(
        `button[data-bs-target="#nav-${searchParams.get("tab")}"]`
      );
      if (elem) {
        elem.click();
        if (elem.classList.contains("active")) {
          setInitialTab(true);
        }
      }
    }

    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  });

  const listenToScroll = () => {
    let heightToHideFrom = 1200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll < heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const tabShowMain = () => {
    document.querySelector('button[data-bs-target="#nav-branch"]').click();
    scrollToTabs();
  };

  const scrollToTabs = () => {
    menusTabs.current.scrollIntoView();
    setTimeout(() => {
      menusTabs.current.scrollIntoView();
    }, 500);
  };

  return (
    <>
      <section id={convertId(data.name)} className="section-branch-location">
        <div className="branch-location">
          <div className=" container">
            <div className="row">
              <div className="col-sm-6">
                <div className="text-center mx-auto">
                  <div className="intro-title main-branch-content">
                    <div className="heading">
                      <HeadingTag
                        className={`textStroke-${data.headingTextStroke[0].color}`}
                        type={data.headingTextStroke[0].titleTag}
                      >
                        {data.headingTextStroke[0].title}
                      </HeadingTag>
                    </div>
                    <div className="heading-sub">
                      <HeadingTag
                        className={data.headingSub[0].color}
                        type={
                          data.headingSub[0].titleTag != null
                            ? data.headingSub[0].titleTag
                            : "h2"
                        }
                      >
                        {data.headingSub[0].title}
                      </HeadingTag>
                    </div>
                  </div>
                  <div className="content-wrapper">
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{ __html: data.content }}
                    ></div>

                    {data.tel && (
                      <a className="tel" href={`tel:${data.tel}`}>
                        {data.tel}
                      </a>
                    )}

                    {data.hours && (
                      <>
                        <h4>Hours</h4>
                        <ul>
                          {data.hours.map((hr, key) => (
                            <li key={key}>{hr.hoursOpen}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    <div className="social-media">
                      <h4>
                        {data.socialMedia.facebookUrl !== null ||
                        data.socialMedia.twitterUrl !== null ||
                        data.socialMedia.instagramUrl !== null
                          ? data.socialMedia.name
                          : ""}
                      </h4>
                      <ul>
                        {data.socialMedia.facebookUrl !== null && (
                          <li>
                            <Link
                              to={data.socialMedia.facebookUrl}
                              target="_blank"
                            >
                              <svg
                                width="29"
                                height="28"
                                viewBox="0 0 29 28"
                                fill="none"
                                className="location-social-icon"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M28.0127 2.26904C28.0127 1.84521 27.8311 1.48193 27.5283 1.1792C27.2256 0.937012 26.8623 0.755371 26.499 0.755371H2.40137C1.91699 0.755371 1.55371 0.937012 1.31152 1.1792C1.00879 1.48193 0.887695 1.84521 0.887695 2.26904V26.3667C0.887695 26.7905 1.00879 27.1538 1.31152 27.4565C1.55371 27.7593 1.91699 27.8804 2.40137 27.8804H15.3584V17.3452H11.8467V13.2886H15.3584V10.2612C15.3584 8.56592 15.8428 7.23389 16.8115 6.26514C17.7803 5.35693 19.0518 4.87256 20.626 4.87256C21.8369 4.87256 22.9268 4.93311 23.7744 4.99365V8.68701H21.5947C20.8076 8.68701 20.2627 8.86865 19.96 9.23193C19.7178 9.53467 19.5967 10.019 19.5967 10.6851V13.2886H23.6533L23.1084 17.3452H19.5967V27.8804H26.499C26.9229 27.8804 27.2861 27.7593 27.5889 27.4565C27.8311 27.1538 28.0127 26.7905 28.0127 26.3667V2.26904Z"
                                  fill="#EF4923"
                                />
                              </svg>
                            </Link>
                          </li>
                        )}

                        {data.socialMedia.twitterUrl !== null && (
                          <li>
                            <Link
                              to={data.socialMedia.twitterUrl}
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="28"
                                height="28"
                              >
                                {/* Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}
                                <path
                                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                  fill="#EF4923"
                                />
                              </svg>
                            </Link>
                          </li>
                        )}

                        {data.socialMedia.instagramUrl !== null && (
                          <li>
                            <Link
                              to={data.socialMedia.instagramUrl}
                              target="_blank"
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.2829 7.35498C10.4079 7.35498 7.31998 10.5034 7.31998 14.3179C7.31998 18.1929 10.4079 21.2808 14.2829 21.2808C18.0973 21.2808 21.2458 18.1929 21.2458 14.3179C21.2458 10.5034 18.0973 7.35498 14.2829 7.35498ZM14.2829 18.8589C11.8004 18.8589 9.74185 16.8608 9.74185 14.3179C9.74185 11.8354 11.7399 9.8374 14.2829 9.8374C16.7653 9.8374 18.7633 11.8354 18.7633 14.3179C18.7633 16.8608 16.7653 18.8589 14.2829 18.8589ZM23.1227 7.11279C23.1227 6.20459 22.3961 5.47803 21.4879 5.47803C20.5797 5.47803 19.8532 6.20459 19.8532 7.11279C19.8532 8.021 20.5797 8.74756 21.4879 8.74756C22.3961 8.74756 23.1227 8.021 23.1227 7.11279ZM27.7243 8.74756C27.6032 6.56787 27.1188 4.63037 25.5446 3.05615C23.9704 1.48193 22.0329 0.997559 19.8532 0.876465C17.6129 0.755371 10.8922 0.755371 8.65201 0.876465C6.47232 0.997559 4.59537 1.48193 2.9606 3.05615C1.38638 4.63037 0.902008 6.56787 0.780914 8.74756C0.659821 10.9878 0.659821 17.7085 0.780914 19.9487C0.902008 22.1284 1.38638 24.0054 2.9606 25.6401C4.59537 27.2144 6.47232 27.6987 8.65201 27.8198C10.8922 27.9409 17.6129 27.9409 19.8532 27.8198C22.0329 27.6987 23.9704 27.2144 25.5446 25.6401C27.1188 24.0054 27.6032 22.1284 27.7243 19.9487C27.8454 17.7085 27.8454 10.9878 27.7243 8.74756ZM24.818 22.3101C24.3942 23.521 23.4254 24.4292 22.2751 24.9136C20.4586 25.6401 16.2204 25.4585 14.2829 25.4585C12.2848 25.4585 8.04654 25.6401 6.29068 24.9136C5.07974 24.4292 4.17154 23.521 3.68716 22.3101C2.9606 20.5542 3.14224 16.3159 3.14224 14.3179C3.14224 12.3804 2.9606 8.14209 3.68716 6.32568C4.17154 5.17529 5.07974 4.26709 6.29068 3.78271C8.04654 3.05615 12.2848 3.23779 14.2829 3.23779C16.2204 3.23779 20.4586 3.05615 22.2751 3.78271C23.4254 4.20654 24.3336 5.17529 24.818 6.32568C25.5446 8.14209 25.3629 12.3804 25.3629 14.3179C25.3629 16.3159 25.5446 20.5542 24.818 22.3101Z"
                                  fill="#EF4923"
                                />
                              </svg>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="wrapper-img mobile">
                  {data.image && <CmsImage image={data.image} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="branch-menu" id="menus" ref={menusTabs}>
        {data.tabMenus && (
          <div className="menu-wrapper">
            <nav>
              <div className="container-menu">
                <div className="nav nav-tabs" id="menu" role="tablist">
                  <button
                    className="nav-link d-none"
                    id="nav-branch-content"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-branch"
                    type="button"
                    role="tab"
                    aria-controls="nav-branch"
                    aria-selected="true"
                  >
                    Content
                  </button>

                  {data.tabMenus &&
                    data.tabMenus.map((item, i) =>
                      item.fieldGroupName ===
                      "Page_Sections_Sections_BranchLocation_TabMenus_ContentLink" ? (
                        item.tabLink && (
                          <CmsLink
                            className="nav-link"
                            role="tab"
                            aria-selected="false"
                            link={item.tabLink}
                            type={1}
                            key={i}
                          >
                            {item.tabName}
                          </CmsLink>
                        )
                      ) : (
                        <button
                          key={i}
                          className="nav-link"
                          id={`nav-${convertId(item.tabName)}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#nav-${convertId(item.tabName)}`}
                          type="button"
                          role="tab"
                          aria-controls={`nav-${convertId(item.tabName)}`}
                          aria-selected="false"
                          onClick={scrollToTabs}
                        >
                          {item.tabName}
                        </button>
                      )
                    )}
                </div>
              </div>
            </nav>
          </div>
        )}
        {/* <div className="branch-textture"></div> */}
        <div className="menu-content">
          <div className="position-relative">
            <img className="confetti-left" src={AmigoConfettiLeft} alt="" />
          </div>
          <div className="position-relative">
            <img className="confetti-right" src={AmigoConfettiRight} alt="" />
          </div>

          <div className="tab-content" id="nav-tabContent">
            {/* MainContent */}
            <div
              className="tab-pane fade show active"
              id="nav-branch"
              role="tabpanel"
              aria-labelledby="nav-branch-content"
            >
              <div className="container">
                {data.title && (
                  <div className="main-intro">
                    <Content className="heading mobile">{data.title}</Content>
                  </div>
                )}

                {data.featuredMenu && (
                  <div className="row featured-menus">
                    {data.featuredMenu.map((featured, i) => (
                      <div key={i} className="col-sm-6 d-flex">
                        <div className="col-sm-6">
                          <div className="image-wrapper">
                            <CmsImage image={featured.image} />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="featured-caption">
                            <Content>{featured.content}</Content>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="row special-events">
                  {data.eventsName.specialEventsLabel && (
                    <Content className="intro mobile">
                      {data.eventsName.specialEventsLabel}
                    </Content>
                  )}

                  {data.eventsName.specialEvents &&
                    data.eventsName.specialEvents.map((events, i) => (
                      <div className="col-sm-4" key={i}>
                        <Content className="special-events-caption">
                          {events.content}
                        </Content>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* End of MainContent */}

            {data.tabMenus &&
              data.tabMenus.map((item, i) => (
                <div
                  key={i}
                  className="tab-pane fade "
                  id={`nav-${convertId(item.tabName)}`}
                  role="tabpanel"
                  aria-labelledby={`nav-${convertId(item.tabName)}-tab`}
                >
                  <div className="container">
                    <div
                      className="tab-back"
                      onClick={tabShowMain}
                      onKeyDown={tabShowMain}
                    >
                      <img src={IconBack} alt="Back" />
                      Back
                    </div>
                  </div>

                  {item.fieldGroupName ===
                    "Page_Sections_Sections_BranchLocation_TabMenus_ContentText" && (
                    <div className="bar-menu-wrapper">
                      {isVisible && item.contentRows !== null && (
                        <div className="scrollSpyPosition">
                          <Scrollspy
                            className="scrollspy"
                            currentClassName="isCurrent"
                          >
                            {item.contentRows &&
                              item.contentRows.map((menu, i) => (
                                <li
                                  className={
                                    item.contentRows.length >= 7
                                      ? "text-sm"
                                      : "text-default"
                                  }
                                  key={i}
                                >
                                  <a
                                    href={`#${convertId(
                                      menu.label.replace(/(<([^>]+)>)/gi, "")
                                    )}`}
                                    dangerouslySetInnerHTML={{
                                      __html: menu.label,
                                    }}
                                  ></a>
                                </li>
                              ))}
                          </Scrollspy>
                        </div>
                      )}

                      <div className="main-intro">
                        <Content className="heading mobile">
                          {item.title}
                        </Content>
                      </div>

                      <div className="scrollspy-bar-menu">
                        {item.contentRows &&
                          item.contentRows.map((menu, i) => (
                            <section
                              key={i}
                              id={`${convertId(
                                menu.label.replace(/(<([^>]+)>)/gi, "")
                              )}`}
                              className="bar-menu-lists"
                            >
                              <Content className="heading-text-stroke branch-location-old">
                                {menu.label}
                              </Content>

                              <div className="bar-child-content">
                                <div className="row">
                                  {menu.contentLists &&
                                    menu.contentLists.map((row, key) => (
                                      <div
                                        className="col-sm-6 px-5 mb-5"
                                        key={key}
                                      >
                                        <Content className="menu-title">
                                          {row.theContent}
                                        </Content>
                                      </div>
                                    ))}
                                  <div className="menu-add-info">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: menu.contentAdditionalInfo,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          ))}
                      </div>
                    </div>
                  )}

                  {item.fieldGroupName ===
                    "Page_Sections_Sections_BranchLocation_TabMenus_ContentFile" && (
                    <div className="bar-menu-wrapper text-center ">
                      <Content className="main-intro mobile">
                        {item.title}
                      </Content>

                      {item.filePdf && (
                        <a
                          href={item.filePdf.localFile.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.fileImage && (
                            <CmsImage image={item.fileImage} />
                          )}
                        </a>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment BranchLocation on WpPage_Sections_Sections_BranchLocation {
    content
    tel
    title
    name
    # barLabel
    # barLabelTag
    # diningLabel
    # diningLabelTag
    # deliveryUrl
    # deliveryContent
    # orderOnlineUrl
    # orderOnlineContent
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
    hours {
      fieldGroupName
      hoursOpen
    }
    headingSub {
      fieldGroupName
      title
      titleTag
      color
    }
    headingTextStroke {
      fieldGroupName
      title
      titleTag
      color
    }
    fieldGroupName
    featuredMenu {
      fieldGroupName
      content
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
    eventsName {
      fieldGroupName
      specialEventsLabel
      specialEvents {
        fieldGroupName
        content
      }
    }
    socialMedia {
      fieldGroupName
      facebookUrl
      instagramUrl
      name
      twitterUrl
    }

    tabMenus {
      ... on WpPage_Sections_Sections_BranchLocation_TabMenus_ContentText {
        fieldGroupName
        tabName
        title
        contentRows {
          fieldGroupName
          label
          contentAdditionalInfo
          contentLists {
            fieldGroupName
            theContent
          }
        }
      }
      ... on WpPage_Sections_Sections_BranchLocation_TabMenus_ContentFile {
        fieldGroupName
        tabName
        title
        fileImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        filePdf {
          altText
          localFile {
            absolutePath
            publicURL
            url
          }
        }
      }
      ... on WpPage_Sections_Sections_BranchLocation_TabMenus_ContentLink {
        fieldGroupName
        tabName
        tabLink {
          target
          title
          url
        }
      }
    }

    # barMenus {
    #   fieldGroupName
    #   additionalInfo
    #   barMenuName {
    #     fieldGroupName
    #     barMenuLabel
    #     barMenuTag
    #     menu {
    #       fieldGroupName
    #       description
    #       name
    #       nameTag
    #     }
    #   }
    # }
    # diningMenus {
    #   fieldGroupName
    #   image {
    #     altText
    #     localFile {
    #       childImageSharp {
    #         gatsbyImageData
    #       }
    #       extension
    #       publicURL
    #     }
    #   }
    #   files {
    #     localFile {
    #       absolutePath
    #       publicURL
    #       url
    #     }
    #     altText
    #   }
    # }
  }
`;

export default BranchLocation;
