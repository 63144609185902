import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import AmigoConfettiLeft from "../../images/confetti-left.svg";
import AmigoConfettiRight from "../../images/confetti-right.svg";

const IntroWithConfetti = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section-with-confetti">
      <div className="confetti">
        <div className="position-relative" data-aos="fade-right">
          <img className="confetti-left" src={AmigoConfettiLeft} alt="" />
        </div>
        <div
          data-aos="fade-down"
          className="intro-title position-relative"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        <div className="position-relative" data-aos="fade-left">
          <img className="confetti-right" src={AmigoConfettiRight} alt="" />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment IntroWithConfetti on WpPage_Sections_Sections_IntroWithConfetti {
    fieldGroupName
    name
    content
  }
`;

export default IntroWithConfetti;
