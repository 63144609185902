// Handle <head> meta and render our sections

import React from "react";
import { Helmet } from "react-helmet";
import RenderSections from "../utilities/render-sections";

const Builder = ({
  title,
  page,
  sections,
  themeSettings,
  prefix,
  location,
}) => {
  return (
    <>
      <Helmet>
        <title>
          {page.metaTitle ? page.metaTitle : title} -{" "}
          {themeSettings.siteMetaTitle}
        </title>
        {page.metaDescription && (
          <meta name="description" content={page.metaDescription} />
        )}
        {page.pageCssOverrides && <style>{page.pageCssOverrides}</style>}
        {themeSettings.customCssOverrides && (
          <style>{themeSettings.customCssOverrides}</style>
        )}
      </Helmet>
      <RenderSections sections={sections} prefix={prefix} location={location} />
    </>
  );
};

export default Builder;
