import React, { useEffect, useState, useRef } from "react";

import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Link from "../utilities/link";
import AmigoConfettiLeft from "../../images/confetti-branch-location-left.svg";
import AmigoConfettiRight from "../../images/confetti-branch-location-right.svg";
import Scrollspy from "react-scrollspy";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";

const SectionMenus = ({ data, location }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [height, setHeight] = useState(0);
  const [initialTab, setInitialTab] = useState(false);

  let searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (initialTab === false && searchParams.get('tab')) {
      let elem = document.querySelector(`button[data-bs-target="#nav-${searchParams.get('tab')}"]`);
      if (elem) {
        elem.click();
        if (elem.classList.contains('active')) {
          setInitialTab(true);
        }
      }
    }

    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  });

  const listenToScroll = () => {
    let heightToHideFrom = 1000;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    setIsVisible(false);
    if (winScroll > heightToHideFrom) {
      setIsVisible(true);
    }
  };

  // const tabShowMain = () => {
  //   document.querySelector('button[data-bs-target="#nav-branch"]').click();
  // };

  const tabMenuAnchor = useRef(null);
  const scrollToTabMenu = () => {
    tabMenuAnchor.current.scrollIntoView();
    setTimeout(() => {
      tabMenuAnchor.current.scrollIntoView();
    }, 500)
  }

  return (
    <section
      id={convertId(data.name)}
      className="branch-menu pb-5 menu-sections has-tab-menu"
    >
      <div id="tab-menu" ref={tabMenuAnchor}> </div>

      {data.tabMenus && (
        <div className="menu-wrapper">
          <nav>
            <div className="container-menu">
              <div className="nav nav-tabs" id="menus" role="tablist">
                {/* <button
                  className="nav-link d-none"
                  id="nav-branch-content"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-branch"
                  type="button"
                  role="tab"
                  aria-controls="nav-branch"
                  aria-selected="true"
                >
                  Content
                </button> */}

                {data.tabMenus &&
                  data.tabMenus.map((item, i) =>
                    item.fieldGroupName ===
                    "Page_Sections_Sections_SectionMenus_TabMenus_ContentLink" ? (
                      item.tabLink && (
                        <CmsLink
                          className="nav-link"
                          role="tab"
                          aria-selected="false"
                          link={item.tabLink}
                          type={1}
                          key={i}
                        >
                          {item.tabName}
                        </CmsLink>
                      )
                    ) : (
                      <button
                        key={i}
                        className={`nav-link ${i === 0 ? "active" : null}`}
                        id={`nav-${convertId(item.tabName)}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-${convertId(item.tabName)}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-${convertId(item.tabName)}`}
                        aria-selected="false"
                        onClick={scrollToTabMenu}
                      >
                        {item.tabName}
                      </button>
                    )
                  )}
              </div>
            </div>
          </nav>
        </div>
      )}

      <div className="menu-content">
        <div className="position-relative">
          <img className="confetti-left" src={AmigoConfettiLeft} alt="" />
        </div>
        <div className="position-relative">
          <img className="confetti-right" src={AmigoConfettiRight} alt="" />
        </div>

        <div className="tab-content" id="nav-tabContent">
          {data.tabMenus &&
            data.tabMenus.map((item, i) => (
              <div
                key={i}
                className={`tab-pane fade ${i === 0 ? "active show" : ""} `}
                id={`nav-${convertId(item.tabName)}`}
                role="tabpanel"
                aria-labelledby={`nav-${convertId(item.tabName)}-tab`}
              >
                {item.fieldGroupName ===
                  "Page_Sections_Sections_SectionMenus_TabMenus_ContentText" && (
                  <div className="bar-menu-wrapper">
                    {isVisible && item.contentRows !== null && (
                      <div className="scrollSpyPosition">
                        <Scrollspy
                          className="scrollspy"
                          currentClassName="isCurrent"
                        >
                          {item.contentRows &&
                            item.contentRows.map((menu, i) => (
                              <li
                                className={
                                  item.contentRows.length >= 7
                                    ? "text-sm"
                                    : "text-default"
                                }
                                key={i}
                              >
                                <a
                                  href={`#${convertId(
                                    menu.label.replace(/(<([^>]+)>)/gi, "")
                                  )}`}
                                  dangerouslySetInnerHTML={{
                                    __html: menu.label,
                                  }}
                                ></a>
                              </li>
                            ))}
                        </Scrollspy>
                      </div>
                    )}

                    <div className="main-intro">
                      <Content className="heading mobile">{item.title}</Content>
                    </div>

                    <div className="scrollspy-bar-menu">
                      {item.contentRows &&
                        item.contentRows.map((menu, i) => (
                          <section
                            key={i}
                            id={`${convertId(
                              menu.label.replace(/(<([^>]+)>)/gi, "")
                            )}`}
                            className="bar-menu-lists"
                          >
                            <Content className="heading-text-stroke">
                              {menu.label}
                            </Content>

                            <div className="bar-child-content">
                              <div className="row">
                                {menu.contentLists &&
                                  menu.contentLists.map((row, key) => (
                                    <div
                                      className="col-sm-6 px-5 mb-5"
                                      key={key}
                                    >
                                      <Content className="menu-title">
                                        {row.theContent}
                                      </Content>
                                    </div>
                                  ))}
                                <div className="menu-add-info">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: menu.contentAdditionalInfo,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </section>
                        ))}
                    </div>
                  </div>
                )}

                {item.fieldGroupName ===
                  "Page_Sections_Sections_SectionMenus_TabMenus_ContentLeftImage" && (
                  <div className="container">
                    {item.title && (
                      <div className="main-intro">
                        <Content className="heading mobile">
                          {item.title}
                        </Content>
                      </div>
                    )}

                    {item.columnContent && (
                      <div className="row featured-menus">
                        {item.columnContent.map((featured, i) => (
                          <div key={i} className="col-sm-6 d-flex">
                            <div className="col-sm-6">
                              <div className="image-wrapper">
                                <CmsImage image={featured.image} />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="featured-caption">
                                <Content>{featured.content}</Content>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {item.fieldGroupName ===
                  "Page_Sections_Sections_SectionMenus_TabMenus_ContentGrid" && (
                  <div className="container special-events">
                    {item.title && (
                      <div className="main-intro pb-0">
                        <Content className="heading mobile">
                          {item.title}
                        </Content>
                      </div>
                    )}

                    {item.columnContent && (
                      <div className="row featured-menus py-0">
                        {item.columnContent.map((featured, i) => (
                          <div className="col-sm-4" key={i}>
                            <Content className="special-events-caption">
                              {featured.content}
                            </Content>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {item.fieldGroupName ===
                  "Page_Sections_Sections_SectionMenus_TabMenus_ContentFile" && (
                  <div className="bar-menu-wrapper text-center ">
                    <Content className="main-intro mobile">
                      {item.title}
                    </Content>

                    {item.filePdf && (
                      <a
                        href={item.filePdf.localFile.publicURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.fileImage && <CmsImage image={item.fileImage} />}
                      </a>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment SectionMenus on WpPage_Sections_Sections_SectionMenus {
    fieldGroupName
    name

    tabMenus {
      ... on WpPage_Sections_Sections_SectionMenus_TabMenus_ContentGrid {
        fieldGroupName
        tabName
        title
        columnContent {
          fieldGroupName
          content
        }
      }
      ... on WpPage_Sections_Sections_SectionMenus_TabMenus_ContentLeftImage {
        fieldGroupName
        tabName
        title
        columnContent {
          fieldGroupName
          content
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              extension
              publicURL
            }
          }
        }
      }
      ... on WpPage_Sections_Sections_SectionMenus_TabMenus_ContentText {
        fieldGroupName
        tabName
        title
        contentRows {
          fieldGroupName
          label
          contentAdditionalInfo
          contentLists {
            fieldGroupName
            theContent
          }
        }
      }
      ... on WpPage_Sections_Sections_SectionMenus_TabMenus_ContentFile {
        fieldGroupName
        tabName
        title
        fileImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        filePdf {
          altText
          localFile {
            absolutePath
            publicURL
            url
          }
        }
      }
      ... on WpPage_Sections_Sections_SectionMenus_TabMenus_ContentLink {
        fieldGroupName
        tabName
        tabLink {
          target
          title
          url
        }
      }
    }
  }
`;

export default SectionMenus;
