import React from "react";
import convertId from "../../utilities/convert-id";
import { useStaticQuery, graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Link from "../utilities/link";
import AmigoConfettiLeft from "../../images/news-confetti-left.svg";
import AmigoConfettiRight from "../../images/news-confetti-right.svg";

const RecentNews = ({ data }) => {
  const {
    allWpPost: { edges: posts },
  } = useStaticQuery(graphql`
    query RecentNewsPostsQuery {
      allWpPost(sort: { order: DESC, fields: date }, limit: 3, skip: 0) {
        edges {
          node {
            id
            title
            uri
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 760, height: 427)
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section id={convertId(data.name)} className="section-news">
      <div className="position-relative" data-aos="fade-right">
        <img className="confetti-left" src={AmigoConfettiLeft} alt="" />
      </div>

      <div className="container position-relative">
        <div className="text-left" data-aos="fade-up">
          <h1
            className={`news-title textStroke-${data.headingTextStroke[0].color}`}
            dangerouslySetInnerHTML={{
              __html: data.headingTextStroke[0].title,
            }}
          ></h1>
        </div>

        <div className="row" data-aos="fade-down">
          {posts?.length > 0 &&
            posts.map((post, k) => (
              <div key={k} className="col-lg-4 col-md-6 mb-2-6">
                <article className="card card-news">
                  <div className="card-img">
                    {post.node.featuredImage != null ? (
                      <Link to={post.node.uri} className="">
                        <CmsImage image={post.node.featuredImage.node} />
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-body">
                    <Link
                      to={post.node.uri}
                      className="read-more btn-hover-icon-red"
                    >
                      {post.node.title}
                    </Link>
                  </div>
                </article>
              </div>
            ))}
        </div>
      </div>

      <div className="position-relative" data-aos="fade-left">
        <img className="confetti-right" src={AmigoConfettiRight} alt="" />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment RecentNews on WpPage_Sections_Sections_RecentNews {
    fieldGroupName
    name
    headingTextStroke {
      fieldGroupName
      color
      title
    }
  }
`;

export default RecentNews;
