import React, { useEffect, useContext } from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Link from "../utilities/link";
import SelectedLocationContext from "../../store/selected-location-context";
import CmsLink from "../utilities/cms-link";

gsap.registerPlugin(ScrollTrigger);

const HomeHero = ({ data }) => {
  const images = data.parallaxImage;
  const selectedLocationCtx = useContext(SelectedLocationContext);

  useEffect(() => {
    // const tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: `${convertId(data.name)}`,
    //     start: "top top",
    //     end: "bottom top",
    //     scrub: true
    //   }
    // });

    // gsap.utils.toArray(".parallax").forEach(layer => {
    //   const depth = layer.dataset.depth;
    //   const movement = -(layer.offsetHeight * depth)
    //   tl.to(layer, {y: movement, ease: "none"}, 0)
    // });

    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: `${convertId(data.name)}-gsap`,
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        },
      })
      .fromTo(".img-0", { y: 0 }, { y: -200 }, 0)
      .fromTo(".img-1", { y: 100 }, { y: -800 }, 0)
      .fromTo(".img-2", { y: 0 }, { y: -200 }, 0)
      .fromTo(".img-3", { y: 0 }, { y: -500 }, 0)
      .fromTo(".img-4", { y: 0 }, { y: -650 }, 0)
      .fromTo(".img-5", { y: 30 }, { y: -200 }, 0)
      .fromTo(".img-6", { y: 0 }, { y: -250 }, 0)
      .fromTo(".img-7", { y: 0 }, { y: -600 }, 0);

    return () => {
      tl.kill();
    };
  });

  return (
    <>
      <section id={convertId(data.name)} className="home-banner">
        <div className="position-relative" id={`${convertId(data.name)}-gsap`}>
          {images &&
            images.map((image, key) => (
              <div
                key={key}
                className={`position-absolute parallax img-${key}`}
              >
                <CmsImage image={image.image} />
              </div>
            ))}
        </div>

        <div className="container position-relative text-center parallax-intro">
          <div className="home-banner-content mx-auto">
            <div dangerouslySetInnerHTML={{ __html: data.intro }}></div>

            {data.button && (
              <CmsLink className="btn btn-lg btn-secondary btn-hover-icon hover-orange mt-5" link={data.button} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment HomeHero on WpPage_Sections_Sections_HomeHero {
    fieldGroupName
    name
    intro
    button {
      target
      title
      url
    }
    parallaxImage {
      fieldGroupName
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default HomeHero;
