import React, { useContext } from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Link from "../utilities/link";
import HeadingTag from "../utilities/heading-tag";
import SelectedLocationContext from "../../store/selected-location-context";
import CmsLink from "../utilities/cms-link";

const FeaturedDish = ({ data }) => {
  const selectedLocationCtx = useContext(SelectedLocationContext);
  return (
    <section id={convertId(data.name)} className="section-featured-dish">
      <div className="featured-dish container">
        <div className="text-left" data-aos="fade-down">
          <div className="heading-text-stroke">
            <HeadingTag
              type={data.headingTextStroke[0].titleTag}
              className={`textStroke-${data.headingTextStroke[0].color}`}
              dangerouslySetInnerHTML={{
                __html: data.headingTextStroke[0].title,
              }}
            ></HeadingTag>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 mb-hide order-lg-last">
            <div className="" data-aos="fade-left">
              <CmsImage className="image-f-dish" image={data.image} />
            </div>
          </div>

          <div className="col-lg-7 intro-title" data-aos="fade-up">
            <div className="mb-show">
              <CmsImage image={data.image} />
            </div>

            <div className="heading-sub">
              <HeadingTag
                type={data.headingSub[0].titleTag}
                className={data.headingSub[0].color}
              >
                {data.headingSub[0].title}
              </HeadingTag>
            </div>

            <div className="heading-intro">
              <HeadingTag
                type={data.headingIntro[0].titleTag}
                className={data.headingIntro[0].color}
              >
                {data.headingIntro[0].title}
              </HeadingTag>
            </div>

            <div dangerouslySetInnerHTML={{ __html: data.content }}></div>

            {data.button && (
              <div className="d-lg-block d-sm-flex mt-5">
                <CmsLink className="btn btn-primary btn-hover-icon" link={data.button} />
              </div>
            )}

          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FeaturedDish on WpPage_Sections_Sections_FeaturedDish {
    fieldGroupName
    name
    content
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
    headingTextStroke {
      fieldGroupName
      color
      title
      titleTag
    }

    headingSub {
      fieldGroupName
      color
      title
      titleTag
    }
    headingIntro {
      fieldGroupName
      title
      titleTag
      color
    }
    button {
      target
      title
      url
    }
  }
`;

export default FeaturedDish;
