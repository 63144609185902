import { graphql } from "gatsby";
import IframeResizer from "iframe-resizer-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import convertId from "../../utilities/convert-id";

const MenuSheet = ({ data, location }) => {
  const menuName = convertId(data.menuName);

  const [menuUrl, setMenuUrl] = useState(
    `${process.env.MENU_IFRAME_URL}/amigo/${menuName}`
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params) {
      const secret = params.get("secret"),
        tab = params.get("tab");

      if (secret) {
        setMenuUrl(
          `${process.env.MENU_IFRAME_URL}/api/draft?slug=amigo/${menuName}&secret=${secret}`
        );
      } else if (tab) {
        setMenuUrl(
          `${process.env.MENU_IFRAME_URL}/amigo/${menuName}?tab=${tab}`
        );
      }
    }
  }, [location.search, menuName, setMenuUrl]);

  return (
    <section id={convertId(data.name)} className="section section-menu-sheet">
      <IframeResizer src={menuUrl} style={{ width: "1px", minWidth: "100%" }} />
    </section>
  );
};

export const query = graphql`
  fragment MenuSheet on WpPage_Sections_Sections_MenuSheet {
    fieldGroupName
    name
    menuName
  }
`;

export default MenuSheet;
