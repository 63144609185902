import { graphql } from "gatsby";
import React from "react";
import Builder from "../components/global/builder";

const Page = ({
  data: {
    wpPage: {
      title,
      page,
      sections: { sections },
    },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  location,
}) => {
  return (
    <Builder
      title={title}
      page={page}
      sections={sections}
      themeSettings={themeSettings}
      location={location}
    />
  );
};

export const pageQuery = graphql`
  query Page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
      sections {
        sections {
          ... on WpPage_Sections_Sections_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Sections_Sections_IntroWithConfetti {
            ...IntroWithConfetti
          }
          ... on WpPage_Sections_Sections_LocationMap {
            ...LocationMap
          }
          ... on WpPage_Sections_Sections_FeaturedDish {
            ...FeaturedDish
          }
          ... on WpPage_Sections_Sections_FeaturedNews {
            ...FeaturedNews
          }
          ... on WpPage_Sections_Sections_FeaturedMap {
            ...FeaturedMap
          }
          ... on WpPage_Sections_Sections_CenteredTitle {
            ...CenteredTitle
          }
          ... on WpPage_Sections_Sections_BranchLocation {
            ...BranchLocation
          }
          ... on WpPage_Sections_Sections_CenteredContent {
            ...CenteredContent
          }
          ... on WpPage_Sections_Sections_ContentWithImage {
            ...ContentWithImage
          }
          ... on WpPage_Sections_Sections_ContactForm {
            ...ContactForm
          }
          ... on WpPage_Sections_Sections_BranchesContentRightImage {
            ...BranchesContentRightImage
          }
          ... on WpPage_Sections_Sections_SectionMenus {
            ...SectionMenus
          }
          ... on WpPage_Sections_Sections_MenuSheet {
            ...MenuSheet
          }
          ... on WpPage_Sections_Sections_RecentNews {
            ...RecentNews
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Page;
