// You must add any sections you create to this file.

import BranchesContentRightImage from "../components/sections/branch-content-with-image";
import BranchLocation from "../components/sections/branch-location";
import CenteredTitle from "../components/sections/centered-title";
import FeaturedDish from "../components/sections/featured-dish";
import FeaturedMap from "../components/sections/featured-map";
import FeaturedNews from "../components/sections/featured-news";
import HomeHero from "../components/sections/home-hero-parallax";
import IntroWithConfetti from "../components/sections/intro-with-confetti";
import LocationMap from "../components/sections/location-map";
import SectionMenus from "../components/sections/menus";

import CenteredContent from "../components/sections/centered-content";
import ContactForm from "../components/sections/contact-form";
import ContentWithImage from "../components/sections/content-with-image";
import MenuSheet from "../components/sections/menu-sheet";
import RecentNews from "../components/sections/recent-news";

const sectionComponents = {
  HomeHero: HomeHero,
  FeaturedDish: FeaturedDish,
  FeaturedMap: FeaturedMap,
  FeaturedNews: FeaturedNews,
  IntroWithConfetti: IntroWithConfetti,
  LocationMap: LocationMap,
  CenteredTitle: CenteredTitle,
  BranchLocation: BranchLocation,
  SectionMenus: SectionMenus,
  BranchesContentRightImage: BranchesContentRightImage,
  RecentNews: RecentNews,

  CenteredContent: CenteredContent,
  ContentWithImage: ContentWithImage,
  ContactForm: ContactForm,

  MenuSheet: MenuSheet,
};

export default sectionComponents;
